<template>
<div>
  <a-table :columns="columns" :data-source="dataSource" bordered>
    <template #bodyCell="{ column, text, record }">
      <template v-if="['uid', 'name', 'time', 'riskLevel', 'location', 'userUuid'].includes(column.dataIndex)">
        <div>
          <a-input
              v-if="editableData[record.key]"
              v-model:value="editableData[record.key][column.dataIndex]"
              style="margin: -5px 0"
          />
          <template v-else>
            {{ text }}
          </template>
        </div>
      </template>
    </template>
  </a-table>
</div>
</template>

<script>
import { cloneDeep } from 'lodash-es';
import { defineComponent, reactive, ref } from 'vue';
import { collection, getDocs, getFirestore } from "firebase/firestore";


const columns = [{
  title: 'Record Uid',
  dataIndex: 'uid',
  width: '10%',
}, {
  title: 'name',
  dataIndex: 'name',
  width: '10%',
}, {
  title: 'time',
  dataIndex: 'time',
  width: '10%',
}, {
  title: 'location',
  dataIndex: 'location',
  width: "30%"
}, {
  title: 'Risk Level',
  dataIndex: 'riskLevel',
  width: "10%"
}, {
  title: 'User Uid',
  dataIndex: 'userUuid'
}];
const data = [];

export default {
  name: "Home",
  setup() {
    const dataSource = ref(data);
    const editableData = reactive({});

    const edit = uid => {
      editableData[uid] = cloneDeep(dataSource.value.filter(item => uid === item.uid)[0]);
    };

    const save = uid => {
      console.log("On save", uid)
      Object.assign(dataSource.value.filter(item => uid === item.uid)[0], editableData[uid]);
      delete editableData[uid];
    };

    const cancel = uid => {
      delete editableData[uid];
    };


    return {
      dataSource,
      columns,
      editingKey: '',
      editableData,
      edit,
      save,
      cancel,
    };
  },
  async created() {
    const dataSource = ref(data)
    const db = getFirestore();
    const querySnapshot = await getDocs(collection(db, "CheckIn"));
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let singleOne = doc.data();
      singleOne['uid'] = doc.id
      dataSource.value.push(singleOne)
      console.log(doc.id, " => ", doc.data());
    });
    console.log(dataSource)
  }
}
</script>

<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}
</style>